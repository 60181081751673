import { ProvideAuth } from 'middlewares/auth';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';

import { AwsConfig } from 'config/aws';

import './styles/App.css';
import { Amplify } from '@aws-amplify/core';
import { Suspense } from 'react';
import LoadingPage from 'pages/Loading';
import { ToastProvider } from 'components/Common/Toast';

Amplify.configure(AwsConfig);

const App: React.FC = () => {
  return (
    <ProvideAuth>
      <ToastProvider>
        <BrowserRouter>
          <Suspense fallback={<LoadingPage />}>
            <AppRoutes />
          </Suspense>
        </BrowserRouter>
      </ToastProvider>
    </ProvideAuth>
  );
};

export default App;
