export const constants = {
  GROUP_FILTER_DEFAULT: 'Filter by group',
} as const;

export const menuName = new Map<string, string>([
  ['home', 'Home'],
  ['skpay', 'Pay DR'],
  ['history', '변경 이력'],
  ['shutdown', '비상 공지 전환'],
  ['shutdown-notice', '[구]공지 전환'],
  ['11st', '11번가'],
  ['gifticon', '기프티콘'],
  ['encoupon', '엔쿠폰'],
  ['notice', '공지 전환'],
]);

export const changeTypes = new Map<string, string>([
  ['TTL', 'TTL 변경'],
  ['SHUTDOWN', '공지 설정'],
  ['RECOVER', '서비스 원복'],
])

export enum PublicTrafficBlockJobType {
    BLOCK = "방화벽 외부 트래픽 차단 작업",
    UNBLOCK = "방화벽 외부 트래픽 차단 해제 작업",
}
