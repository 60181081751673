import React, { MouseEvent, useState } from 'react';
import { useAuth } from 'middlewares/auth';
import { Link, useNavigate } from 'react-router-dom';
import UserCircleIcon from "@heroicons/react/solid/UserCircleIcon"
import { menuName } from '../constants';

interface IHeaderProps {
  menu: string;
  submenu?: string;
}
interface IMenuItemProps {
  current: string;
  name: string;
  path: string;
  isMobile?: boolean;
}
interface IExpandMenuItemProps {
  current: string;
  currentSub?: string;
  name: string;
  subItems: Array<{name: string, path: string}>
  isMobile?: boolean;
}
const DEVELOPERS = ['1101318', '1101527', '1100235']

const MenuItem = ({ current, name, path, isMobile, ...props }: IMenuItemProps) => {
  return (
    <div className={`${
      isMobile ? 'text-lg block' : 'text-base lg:text-lg flex justify-center rounded-md'
    }`}>
    <Link
      to={path}
      
      aria-current={`${current === name ? 'page' : 'false'}`}
      {...props}
    >      
    <div className={`${
        current === name
          ? 'bg-teal-800 text-white'
          : 'text-gray-300 hover:bg-teal-800 hover:text-white'
      } px-3 py-2 rounded-md whitespace-nowrap w-full `}>{menuName.get(name)}
    </div>
    </Link></div>
  );
};
const ExpandMenuItem = ({ current, currentSub, name, subItems, isMobile }: IExpandMenuItemProps) => {
  const [hover, setHover] = useState<boolean>(false);
  
  return (
    <div className={`${isMobile ? 'text-lg block' : 'text-base lg:text-lg flex justify-center'} cursor-pointer `}
    onMouseOver={() => {setHover(true)}} onMouseLeave={() => setHover(false)} >
      <div className={`${
      current === name
        ? 'bg-teal-800 text-white'
        : 'text-gray-300 hover:bg-teal-800 hover:text-white'
    } px-3 py-2 rounded-md whitespace-nowrap w-full`}
      >
        {menuName.get(name)}
      </div>
      <div className='grid grid-flow-row my-1 bg-teal-50 divide-y-2'>
      {isMobile && subItems.map((item, idx) =>
        <Link 
            key={idx}
            to={item.path} 
            >
            <div className={`${
              (currentSub === item.name && current === name)
                ? 'bg-teal-600 text-gray-100'
                : 'text-gray-600 hover:bg-teal-600 hover:text-gray-100'
            } pl-8 py-2 whitespace-nowrap cursor-pointer text-base block`}>              
            {menuName.get(item.name)}
            </div>
        </Link>
      )}
      </div>
      {hover && !isMobile &&
      <div className='absolute z-10 top-12 py-2 grid' >
        {subItems.map((item, idx) => 
          <Link 
          key={idx}
          to={item.path} 
          className={`${(current === name && currentSub == item.name) ? 'bg-teal-600 text-gray-100' : 'bg-teal-50 text-gray-700'} 
          first:px-8 py-1 w-full cursor-pointer 
          hover:bg-teal-600 hover:text-gray-100 font-medium text-center text-base border border-gray-300 border-t-0`}
          >{menuName.get(item.name)}
          </Link>
        )}
      </div>}
    </div>
    
  );
};
const AppHeader: React.FC<IHeaderProps> = ({ menu, submenu }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { userInfo, isAuthenticated, signIn, signOut, checkAuth } = useAuth();
  const navigate = useNavigate();
  const preventFocusOut = (event: MouseEvent) => {
    event.preventDefault();
  };
  const handleClickSignOut = () => {
    navigate('/');
    signOut();
  };

  return (
    <nav className="bg-teal-900">
      <div className="mx-auto md:mx-4 xl:mx-36">
        <div className="relative flex items-center justify-between h-14">
          <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-white hover:bg-teal-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={mobileMenuOpen}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <span className="text-gray-300 px-3 py-2 rounded-md text-lg font-bold">
              Domain Switch
            </span>
          </div>
          <div className="flex place-items-start justify-self-start sm:items-stretch sm:justify-start">
            <div className="hidden md:block md:ml-1">
              <span
                className="font-bold text-gray-300 px-3 py-2 rounded-md text-xl whitespace-nowrap"
                aria-current="page"
              >
                Domain Switch
              </span>
            </div>
          </div>
          <div className="px-0 lg:px-5 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden md:block md:mx-6">
              <div className="flex space-x-4">
                <MenuItem current={menu} name="home" path="/" />
                <MenuItem current={menu} name="skpay" path="/skpay" />
                <MenuItem current={menu} name="history" path="/history" />
                <ExpandMenuItem current={menu} currentSub={submenu} name="notice" 
                subItems={[
                  {name: '11st', path: "/notice/11st"},
                  {name: 'gifticon', path: "/notice/gifticon"},
                  {name: 'encoupon', path: "/notice/encoupon"}
                ]} />
                <MenuItem current={menu} name="test" path="/test" />
              </div>
            </div>
          </div>
          <div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0 sm:items-stretch sm:justify-start">
            <div className="ml-3 relative">
              <div>
                {isAuthenticated ? (
                  <button
                    type="button"
                    className="bg-teal-900 flex text-sm rounded-full focus:outline-none "
                    id="user-menu-button"
                    aria-expanded={userMenuOpen}
                    aria-haspopup="true"
                    onClick={() => setUserMenuOpen(!userMenuOpen)}
                    onBlur={() => setUserMenuOpen(false)}
                  >
                    <div className="flex text-gray-300 hover:bg-teal-800 hover:text-white px-1 py-2 rounded-md font-medium">

                      <span className="hidden lg:block px-1 whitespace-nowrap text-base">
                      {userInfo.displayName?.split('/')[0]}
                      </span>
                      <span className="flex lg:hidden px-1 whitespace-nowrap text-lg md:text-base">
                      <UserCircleIcon className='w-10 h-10' />
                      </span>
                    </div>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-border-emerald bg-gray-50 font-bold p-1"
                    id="header-login-button"
                    onClick={() => signIn()}
                  >
                    Login
                  </button>
                )}
              </div>
              {userMenuOpen && (
                <div
                  className="text-right origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={0}
                  onMouseDown={(event) => preventFocusOut(event)}
                >
                  <div
                    className="block rounded-md px-4 py-2 text-xs text-gray-700 border-b"
                    role="menuitem"
                    id="user-menu-item-0"
                  >
                    {userInfo.displayName.split('/')[0].split('(')[0]}/{userInfo.displayName.split('/')[1]}
                  </div>
                  <div
                    className="block rounded-md px-4 py-2 text-xs text-gray-700 hover:bg-gray-200 hover:cursor-pointer"
                    role="menuitem"
                    id="user-menu-item-0"
                    onClick={handleClickSignOut}
                  >
                    로그아웃
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {mobileMenuOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 divide-y">
            <MenuItem current={menu} name="home" path="/" isMobile />
            <MenuItem current={menu} name="skpay" path="/skpay" isMobile />
            <MenuItem
              current={menu}
              name="history"
              path="/history"
              isMobile
            />
              <ExpandMenuItem 
                current={menu} currentSub={submenu} name="notice" 
                subItems={[
                  {name: '11st', path: "/notice/11st"},
                  {name: 'gifticon', path: "/notice/gifticon"},
                  {name: 'encoupon', path: "/notice/encoupon"}
                ]} 
                isMobile
              />           
          </div>
        </div>
      )}
    </nav>
  );
};
export default AppHeader;
