import { useAuth } from 'middlewares/auth';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

const LoginPage = React.lazy(() => import('../pages/LoginPage'));
const HomePage = React.lazy(() => import('../pages/HomePage'));
const PayDRPage = React.lazy(() => import('../pages/PayDRPage'));
const NoticePage = React.lazy(() => import('../pages/NoticePage'));
const NoticeOldPage = React.lazy(() => import('../pages/NoticeOldPage'));
const HistoryPage = React.lazy(() => import('../pages/HistoryPage'));

const AppRoutes: React.FC = () => {
  const auth = useAuth();
  // console.log('loading: ' + auth.isLoading);
  // console.log('authenticated: ' + auth.isAuthenticated);
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />}></Route>
      {!auth.isLoading && (
      <Route path="/">
        <Route index element={<HomePage />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="skpay" element={<PayDRPage />} />
          <Route path="history" element={<HistoryPage />} />
          <Route path="shutdown-notice">
            <Route index element={<NoticeOldPage service="11st"/>}/>
            {/* <Route path="/"> */}
              <Route path="11st" element={<NoticeOldPage service="11st"/>} />
              <Route path="gifticon" element={<NoticeOldPage service="gifticon"/>} />
              <Route path="encoupon" element={<NoticeOldPage service="encoupon"/>} />
            {/* </Route> */}
          </Route>
          <Route path="notice">
            <Route index element={<NoticePage service="11st"/>}/>
            {/* <Route path="/"> */}
              <Route path="11st" element={<NoticePage service="11st"/>} />
              <Route path="gifticon" element={<NoticePage service="gifticon"/>} />
              <Route path="encoupon" element={<NoticePage service="encoupon"/>} />
            {/* </Route> */}
          </Route>
        </Route>
      </Route>
      )}
    </Routes>
  );
};
export default AppRoutes;
