import { useAuth } from 'middlewares/auth';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

interface IPrivateRouteProps {
  group?: string;
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ group }) => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, checkAuth } = useAuth();
  const isAllowed = () => {
    if (!isLoading && !isAuthenticated) {
      return false;
    }
    if (group && !checkAuth(group)) return false;
    return true;
  };
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/login", {replace: true, state: {redirect: window.location.pathname}})
    }
  }, [isLoading, isAuthenticated])
  useEffect(() => {
    if (group && !checkAuth(group)) {
      navigate("/", {replace: true})
    }
  }, [group])
  return (
    <>
      {isAllowed() ? (
        <Outlet />
      ) : (
        <>
          {/* {!isLoading && !isAuthenticated && (
            // <Navigate
            //   to="/login"
            //   replace
            //   state={{ redirect: window.location.href.split('/').at(-1) }}
            // />
            navigate("/login", {replace: true, state: {redirect: window.location.href.split('/').at(-1)}})
          )}
          {group && !checkAuth(group) && navigate("/", {replace: true})
          //  <Navigate to="/" replace />
           } */}
        </>
      )}
    </>
  );
};

export default PrivateRoute;
