import React from 'react';
import AppHeader from 'layouts/AppHeader';

interface RootPageProps {
  menu?: string;
  submenu?: string;
  children: React.ReactNode;
}

const RootPage: React.FC<RootPageProps> = (props) => {
  return (
    <div className='min-h-screen max-h-screen'>
      <AppHeader menu={props.menu ? props.menu : ''} submenu={props.submenu ? props.submenu : ''}/>
      {/* <Sidebar /> */}
      <div className="max-w-full h-screen">
        <div className='p-2 w-full space-y-6 pb-24'>
          {props.children}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RootPage;
