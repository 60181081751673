/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect, useState } from 'react';

import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const authContext = createContext({} as IUseAuth);

export const useAuth = () => {
  return useContext(authContext);
};

export const ProvideAuth: React.FC<IProvideAuthProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

function useProvideAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
  const [currentSession, setCurrentSession] =
    useState<CognitoUserSession | null>(null);
  const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    // console.log('enter');
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          break;
        case 'oAuthSignOut':
          setIsAuthenticated(false);
          break;
        case 'customOAuthState':
          setRedirect(data);
          break;
        default:
      }
    });

    Auth.currentUserPoolUser()
      .then((user: CognitoUser) => {
        user.getSession((error: Error, session: CognitoUserSession | null) => {
          const refreshToken = session?.getRefreshToken();
          if (refreshToken) {
            user.refreshSession(refreshToken, () => {
              setCurrentUser(user);
              setInit(true);
            });
          }
        });
      })
      .catch(() => {
        setCurrentUser(null);
        setInit(true);
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (init) {
      setIsLoading(false);
    }
    if (currentUser) {
      // console.log(currentUser);
      currentUser.getSession(
        (error: Error, session: CognitoUserSession | null) => {
          if (session) {
            setCurrentSession(session);
            setUserInfo((prevState) => {
              return {
                ...prevState,
                groups: session.getIdToken().payload['cognito:groups'],
              };
            });
            setIsAuthenticated(true);
          } else {
            setCurrentSession(null);
            setIsAuthenticated(false);
          }
        },
      );
      currentUser.getUserAttributes((err, attributesArray) => {
        if (attributesArray) {
          const attributes = Object.fromEntries(
            attributesArray.map((x) => [x.Name, x.Value]),
          );
          setUserInfo((prevState) => {
            return {
              ...prevState,
              email: attributes['email'],
              department: attributes['custom:Department'],
              displayName: attributes['custom:DisplayName'],
              employeeID: attributes['custom:EmployeeID'],
            };
          });
        }
      });
    } else {
      setCurrentSession(null);
      setUserInfo({} as IUserInfo);
      setIsAuthenticated(false);
    }

    return () => {};
  }, [init, currentUser]);

  const signIn = (state?: string) => {
    Auth.federatedSignIn({ customProvider: 'Azure-AD', customState: state });
  };

  const signOut = () => {
    Auth.signOut();
  };

  const getAccessToken = () => {
    return currentSession?.getAccessToken().getJwtToken();
  };
  const checkAuth = (group: string) => {
    if (userInfo.groups) return userInfo.groups.includes(group);
    else return false;
  };
  const initRedirect = () => {
    setRedirect(null);
  };
  return {
    currentUser,
    userInfo,
    isAuthenticated,
    isLoading,
    redirect,
    initRedirect,
    signIn,
    signOut,
    getAccessToken,
    checkAuth,
  };
}
